export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29')
];

export const server_loads = [0];

export const dictionary = {
		"/(app)": [~4,[2],[3]],
		"/(app)/about": [7,[2],[3]],
		"/(app)/auth/error": [8,[2],[3]],
		"/(app)/changelog": [9,[2],[3]],
		"/(app)/curators": [~10,[2],[3]],
		"/(app)/explore": [11,[2],[3]],
		"/(app)/extension": [12,[2],[3]],
		"/(app)/for-you": [13,[2],[3]],
		"/(app)/library/bookmarks": [14,[2],[3]],
		"/(app)/library/collections": [15,[2],[3]],
		"/(app)/library/people": [16,[2],[3]],
		"/(app)/login": [17,[2],[3]],
		"/(app)/post/[id]": [18,[2],[3]],
		"/(app)/pricing": [19,[2],[3]],
		"/(app)/privacy-policy": [20,[2],[3]],
		"/(app)/search/[type]": [21,[2],[3]],
		"/(app)/signup": [22,[2],[3]],
		"/(app)/sync-status": [23,[2],[3]],
		"/(app)/terms-of-service": [24,[2],[3]],
		"/(app)/topics": [25,[2],[3]],
		"/(app)/topics/[slug]": [~26,[2],[3]],
		"/(app)/trash": [27,[2],[3]],
		"/(app)/use-cases/designers": [28,[2],[3]],
		"/(app)/user": [29,[2],[3]],
		"/(app)/[...slug=collection]": [~5,[2],[3]],
		"/(app)/[...slug=profile]": [~6,[2],[3]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';