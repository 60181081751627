/**
 * Checks if the given parameter is a valid profile route.
 * A valid profile route consists of a non-empty username,
 * optionally followed by a trailing slash.
 * @param {string} param - The route parameter to check
 * @return {param is string}
 * @satisfies {import('@sveltejs/kit').ParamMatcher}
 */
export function match(param) {
	// Remove trailing slash if present
	const trimmedParam = param.endsWith('/') ? param.slice(0, -1) : param;

	// Check if the trimmed param is non-empty and doesn't contain a slash
	return trimmedParam.length > 0 && !trimmedParam.includes('/');
}
