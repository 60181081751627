/**
 * @param {string} param
 * @return {param is string}
 * @satisfies {import('@sveltejs/kit').ParamMatcher}
 */
export function match(param) {
	// Split the param into parts
	const parts = param.split('/');

	// Check if we have exactly two parts
	if (parts.length !== 2) {
		return false;
	}

	// Check if both parts are non-empty
	if (parts[0].length === 0 || parts[1].length === 0) {
		return false;
	}

	// If we've passed all checks, it's a valid two-level route
	return true;
}
